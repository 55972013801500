<template>
	<div class="w-full h-full flex flex-col">
		<Header :classes="'items-end'">
			<div>
				<h1>Brukere &amp; Tilgang</h1>
				<!-- <h3 v-if="users">Totalt {{users.length}} {{users.length > 1 ? 'brukere' : 'bruker'}}</h3> -->
			</div>
			
			<Tabs 
				class="flex-none justify-center md:justify-start"
				:tabs="[
					{
						name: users && users.length ? 'Personlig brukere ('+users.length+')': 'Personlig brukere',
						alias: 'users',
					},
					{
						name: 'Fellesbrukere',
						alias: 'portals',
					},
				]" 
				:active="activeSection" 
				@click="activeSection = $event.alias"
			/>
		</Header>
		
		<Users v-if="activeSection == 'users'" />
		<Portals v-if="activeSection == 'portals'" />
	</div>
</template>

<script>
	export default {
		components: {
			'Users': () => import('@/views/admin/access/Users.vue'),
			'Portals': () => import('@/views/admin/access/Portals.vue'),
		},
		
		computed:{
			users(){
				return this.$store.getters.getUsers;
			},
		},
		
		data(){
			return {
				activeSection: null,
			}
		},
		
		async mounted(){
			if ( !this.$route.params.section || this.$route.params.section == 'users' ) {
				this.activeSection = 'users';
			}
			else if ( this.$route.params.section == 'portals' ) {
				this.activeSection = 'portals';
			}
		},
	}
</script>

<style lang="scss" >

</style>
