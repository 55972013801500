var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('Header',{attrs:{"classes":'items-end'}},[_c('div',[_c('h1',[_vm._v("Brukere & Tilgang")])]),_c('Tabs',{staticClass:"flex-none justify-center md:justify-start",attrs:{"tabs":[
				{
					name: _vm.users && _vm.users.length ? 'Personlig brukere ('+_vm.users.length+')': 'Personlig brukere',
					alias: 'users',
				},
				{
					name: 'Fellesbrukere',
					alias: 'portals',
				},
			],"active":_vm.activeSection},on:{"click":function($event){_vm.activeSection = $event.alias}}})],1),(_vm.activeSection == 'users')?_c('Users'):_vm._e(),(_vm.activeSection == 'portals')?_c('Portals'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }